// imports
@import "../node_modules/font-awesome/less/font-awesome.less";

@import "../node_modules/bootstrap/less/bootstrap.less";

// Theme
@import "../node_modules/bootswatch/paper/variables.less";
@import "../node_modules/bootswatch/paper/bootswatch.less";
@import (less) "../node_modules/dragular/dist/dragular.css";

@import "variables.less";
@import "mixins.less";
@import "modals.less";
@import "alert.less";
@import "notifications.less";
@import "forms.less";
@import "mosaic.less";
@import "breadcrumbs.less";
@import "tabs.less";
@import "progress-bar.less";
@import "collapse-button.less";
@import "wells.less";
@import "expert-search.less";
@import "consultation-illustration.less";
@import "picture-slider.less";
@import "buttons.less";
@import "grid.less";
@import "menu.less";
@import "panels.less";
@import "accueil.less";
@import "thesaurus.less";

.label.label-lg {
  font-size: 100%;
}

// Override autoprefixer
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], input[type=checkbox] {
  -moz-appearance: checkbox;
}
.radio input[type=radio], .radio-inline input[type=radio], input[type=radio] {
  -moz-appearance: radio;
}

// Menu et dropdowns
.dropdown-menu > li > button {
  // copy from .dropdown-menu > li > a
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: @line-height-base;
  color: @dropdown-link-color;
  white-space: nowrap; // prevent links from randomly breaking onto new lines

  // specific
  width: 100%;
  text-align: left;
  text-transform: none;
}
// Hover/Focus state
.dropdown-menu > li > button {
  // copy from .dropdown-menu > li > a
  &:hover,
  &:focus {
    text-decoration: none;
    color: @dropdown-link-hover-color;
    background-color: @dropdown-link-hover-bg;
  }
}

// Reset
.required-labelsss:after {
    content: " *";
}

// Padding top pour avoir le navbar-fixed-top
body {
  position: relative;
  padding-top: 70px;
}

// Liste d'illustrations avec vignette
.rang {
  width: 20px;
  text-align: center;
}
.illustration-resultats-listing-choices,
.biblio-resultats-listing-choices {
  margin: @line-height-computed 0;
  text-align: center;
}
.biblios-recherche,
.illustrations-recherche {
  margin-top: 15px;
}
.card {
  width: 100%;

  height: @card-height;
  overflow: hidden;

  display: block;
  padding: @card-padding;
  margin-bottom: @line-height-computed;
  line-height: @line-height-base;
  background-color: @card-bg;
  border: 1px solid @card-border;
  border-radius: @card-border-radius;
  .transition(border .2s ease-in-out);

  .box-shadow(2px 2px 2px rgba(0,0,0,.3));

  // Add a hover state for linked versions only
  a&:hover,
  a&:focus,
  a&.active {
    border-color: @link-color;
    text-decoration: none;
  }
}
.biblios-recherche .list-group-item-heading a {
  color: @card-caption-color;
  text-decoration: none;
}
.biblios-recherche  .list-group-item,
.illustrations-recherche .list-group-item {
  &:hover {
    background-color: @mosaic-bg-hover;
    border: 1px solid @mosaic-border-hover;
  }
  &:hover + .list-group-item {
    border-top: 1px solid @mosaic-border-hover;
  }
  &.selected {
    border: 1px solid @link-color;
  }
  &.selected + .list-group-item {
    border-top: 1px solid @link-color;
  }
}
.illustrations-recherche .list-group-item-heading a {
  color: @card-caption-color;
  text-decoration: none;
}
.illustrations-recherche .list-group-item-heading .lock-status {
  margin-left: 10px;
}

.card-left {
  color: @gray-light;
  text-align: center;
  overflow: hidden;
}

.biblios-recherche,
.biblio-expert-search-results,
.illustration-expert-search-results,
.illustration-with-errors-results,
.card-left {
  .vignette-empty {
    color: @gray-light;
    width: @demi-vignette;
    height: @demi-vignette;
  }
  .vignette {
    width: @demi-vignette;
    height: @demi-vignette;
    object-fit: contain;
  }
  .vignette-empty {
    line-height: @demi-vignette;
  }
  .vignette.quart-vignette,
  .vignette-empty.quart-vignette {
    width: @quart-vignette;
    height: @quart-vignette;
    object-fit: contain;
  }
  .vignette-empty.quart-vignette {
    line-height: @quart-vignette - @thumbnail-padding * 2 - 1 /* = border width */;
  }
  .vignette-lot {
    width: @demi-vignette + @thumbnail-padding * 2 + 1 /* = border width */;
    height: @demi-vignette + @thumbnail-padding * 2 + 1 /* = border width */;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
}
.illustration-expert-search-results td,
.illustration-with-errors-results td {
  vertical-align: middle !important;
}
.mosaic-group {
  display: flex;
  flex-wrap: wrap;
}
.mosaic-group .selected .card-left,
.mosaic-group .selected .card-left:hover,
.mosaic-group .selected .mosaic-container,
.mosaic-group .selected .mosaic-container:hover {
  border: 1px solid @link-color;
}
td.vignette-lot-container .card-left.vignette-lot {
  width: 105px;
}
.mosaic-group .card-left,
.mosaic-group .mosaic-container,
.gu-mirror .mosaic-container {
  margin: 15px;
  border-radius: @mosaic-border-radius;
  .box-shadow(2px 2px 2px rgba(0,0,0,.3));
  .transition(border .2s ease-in-out);
  .transition(background .2s ease-in-out);
  .transition(opacity .2s ease-in-out);

  &:hover {
    background-color: @mosaic-bg-hover;
    border: 1px solid @mosaic-border-hover;
  }

  .vignette-title,
  .caption {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 5px 0;
    text-align: left;
    width: @vignette;
    font-weight: bold;

    a {
      display: block;
      width: @mosaic-title-width;
      color: @card-caption-color;
      text-decoration: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .sous-titre {
      font-weight: normal;
      color: @mosaic-caption-color;
      width: @mosaic-subtitle-width;
      padding-left: @mosaic-select-element-width;
    }

    input[name="elementsId"] {
      width: @mosaic-select-element-width;
    }
    .lock-status {
      width: @mosaic-lock-status-width;
    }
  }

  .vignette-empty {
    width: @vignette;
    height: @vignette;
  }
  .vignette {
    object-fit: contain;
    width: @vignette;
    height: @vignette;
  }
  .vignette-empty {
    line-height: @vignette;
  }
  .vignette.quart-vignette,
  .vignette-empty.quart-vignette {
    height: @demi-vignette * 0.9;
    width: @demi-vignette * 0.9;
  }
  .vignette-empty.quart-vignette {
    line-height: @demi-vignette * 0.9;
  }
  .vignette-lot {
    width: @vignette;
    height: @vignette;
  }
}

// Image captions
.card-caption {
  padding: @card-caption-padding;
  padding-left: @demi-vignette+10px;
  color: @card-caption-color;
}

.ligne-vignette {
  .vignette {
    width: 100%;
    text-align: center;
    display: inline-block;
    height: @demi-vignette;
    img {
      height: @demi-vignette;
    }
  }
}

// Image lot
table.vignette-lot {
  line-height: 0.1;
  td {
    text-align: center;
    height: @quart-vignette;
    width: @quart-vignette;
    .quart-vignette {
      img {
        max-height: @quart-vignette;
        max-width: @quart-vignette;
      }
    }
  }
}

// Highlight et fields
.resultFields,
.resultHighlight {
  display: block;
  margin: 5px;
  padding: 5px;

  .resultLine {
    display: block;

    .resultLabel {
      font-style: italic;
      width: 200px;
      text-align: right;
      float: left;
    }

    .resultValues {
      margin-left: 210px;
      padding-left: 10px;
    }

    .resultValues > span {
      display: block;
      text-indent: -10px;
    }

    .resultValues > span mark {
      display: inline;
      padding: .1em .6em;
      color: @label-color;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      background-color: @label-default-bg;
    }
  }
}
// facet
ul.facet {
  margin-bottom: 0px;

  .facet-active {
    font-weight: bold;
  }
  a.facet-inactive{
    & > .fa {
      visibility: hidden;
    }
    &:hover > .fa {
      visibility: visible;
    }
  }
}

.well > .form-group.not-margin-bottom {
  margin-bottom: 0px;
}

// Suggest avec séperateur
div.select-separator {
  border-top: 5px solid #ddd;
}

.collapse--width-auto .collapse-button-panel{
  width: auto;
}
.illustration-with-error-tab-bar {
}

.illustration-with-error-tab-bar--child1 {
  display: inline-block;
}

.illustration-with-error-tab-bar--child2 {
  display: inline-block;
  vertical-align: top;
  padding: 23px 0;
  margin-left: 23px;
}

.aside-sticky-top {
  position: sticky;
  top: 70px; // pas 0 car on a la barre de menu en postion: fixed (64px)

  .btn {
    white-space: normal;
  }
}

.bookmark-icon {
  color: @bookmark-color;
}
h1 .bookmark-icon {
  font-size: @bookmark-size-title;
}

details > summary {
  cursor: pointer;
  display: list-item;
}
