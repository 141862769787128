.picture-slider-wrapper {
  position: relative;
  overflow-y: hidden;
  display: inline-block; // de manière à ce que ce div ait toujours la taille de son contenu, même s'il se trouve dans un tableau

  .picture-slider-wrapper-tab {
    width: 100%;
    background-color: rgba(255, 255, 255, .5);
    transition: opacity .3s ease-in;
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
  }

  &:hover .picture-slider-wrapper-tab {
    opacity: 1;
  }

  .picture-slider-wrapper-open {
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
    border-radius: none;
    box-shadow: none;
    color: black;
    padding: 10px;
    opacity: .7;
    transition: opacity .3s ease-in;

    &:active,
    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.picture-slider-modal {
  text-align: center;

  img {
    max-height: 80vh;
  }

  .picture-empty {
    margin: auto;
    display: block;
    width: 20vmax;
    height: 20vmax;
    line-height: 20vmax;
  }

  &-caption {
    font-size: 18px;
  }
  &-caption, img {
    max-width: 768px;
  }
}
.picture-slider-modal .modal-body {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
