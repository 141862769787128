.alert.am-fade {
  opacity: 1;
  transition: opacity .4s linear;
  &.ng-enter {
    opacity: 0;
    &.ng-enter-active {
      opacity: 1;
    }
  }
  &.ng-leave {
    opacity: 1;
    &.ng-leave-active {
      opacity: 0;
    }
  }
}

.alert.am-fade-enter {
  opacity: 1;
  transition: opacity .4s linear;

  &.ng-enter {
    opacity: 0;
    &.ng-enter-active {
      opacity: 1;
    }
  }

  &.ng-leave {
    display: none;
  }
}

.alert.am-fade-leave {
  opacity: 1;
  transition: opacity .4s linear;

  &.ng-leave {
    opacity: 1;
    &.ng-leave-active {
      opacity: 0;
    }
  }
}
