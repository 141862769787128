.notifications {

  .notifications-container {
    z-index: 2000;
    right: @padding-base-horizontal;
    width: @alert-fixed-width;
  }

  &-absolute {
    position: absolute;
  }

  &-fixed{
    position: fixed;
    top: @padding-base-vertical;
  }
}
