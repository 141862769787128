.accueil-container {
  max-width: 800px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin {
  max-width: 330px;
  margin: 0 auto;
}

.accueil {
  height: 140px;
  padding: 10px;
  background-color: #fff;
  .box-shadow(0 6px 12px rgba(0,0,0,.175));

  display: flex;
  align-items: center;
  justify-content: space-between;

  .accueil-logo-right {
    flex: 0 0 auto;
  }
  .accueil-titre-container {
    flex: 1 1 auto;
    margin-left: 15px;
    margin-right: 5px;
    @media (max-width: @screen-xs-min) {
      margin-left: 0px;
      margin-right: 0px;
    }

    .accueil-titre {
      @media (max-width: @screen-xs-min) {
        display: none;
      }
      margin-top: 15px;
      margin-bottom: 5px;
    }
    .accueil-soustitre {
      @media (max-width: @screen-sm-min) {
        display: none;
      }

      font-size: @font-size-h4;
      line-height: 19px;
    }
  }
  .accueil-logo-left {
    flex: 0 0 auto;
  }
}
