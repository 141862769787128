.navbar > .container .navbar-brand-logo,
.navbar > .container-fluid .navbar-brand-logo {
  float: left;
  padding: 0px 15px;
  line-height: 23px;
  height: 64px;
}

.main-menu-tile-container {
  padding-left: 5px;
  padding-right: 5px;
}
.main-menu-tile {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.main-menu-tile h2 {
  margin-top: 0px;
}

.top-menu .top-menu__phototheque.active > a,
.top-menu .top-menu__phototheque .top-menu__submenu__phototheque li.active > a,
.main-menu-tile__phototheque a {
  .button-variant(white, @categorie-phototheque-color, @categorie-phototheque-color);
}

.top-menu .top-menu__biblio.active > a,
.top-menu .top-menu__biblio .top-menu__submenu__biblio li.active > a,
.main-menu-tile__biblio a {
  .button-variant(white, @categorie-biblio-color, @categorie-biblio-color);
}

.top-menu .top-menu__outils.active > a,
.top-menu .top-menu__outils .top-menu__submenu__outils li.active > a,
.main-menu-tile__outils a {
  .button-variant(white, @categorie-outils-color, @categorie-outils-color);
}

.top-menu .top-menu__thesaurus.active > a,
.top-menu .top-menu__thesaurus .top-menu__submenu__thesaurus li.active > a,
.main-menu-tile__thesaurus a {
  .button-variant(white, @categorie-thesaurus-color, @categorie-thesaurus-color);
}

.dropdown-menu.top-menu__submenu__bookmarks {
  max-width: 350px;
  min-width: 250px;
  max-height: 500px;
  overflow: auto;

  .type-entite {
    font-style: italic;
    color: @gray;
    padding: 3px 20px 3px 10px;
  }
}
