.multivalued-input .input-group-btn,
.multivalued-input .input-group-addon {
  vertical-align: top;
}
.input-group-btn,
.input-group-addon {
  .btn-addon {
    box-shadow: 0px -1px 0px #DDD inset;
    padding-left: 12px;
    padding-right: 12px;
    background-color: buttonface;
    color: inherit;
  }
}

label ~ .checkbox input[type=checkbox] {
  margin-left: 0;
}

.form-inline-block {
  display: inline-block;
}

.form-horizontal label.control-label-nopadding.control-label {
  padding-top: 0;
}

input.form-control.input-nomargin {
  margin: 0;
}

// suggest


.suggest-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  background-image: radial-gradient(circle closest-side, #FFF, rgba(255, 255, 255, 0));
}
.input-lg ~ .suggest-feedback {
  width: @input-height-large;
  height: @input-height-large;
  line-height: @input-height-large;
}
.input-sm ~ .suggest-feedback {
  width: @input-height-small;
  height: @input-height-small;
  line-height: @input-height-small;
}

.form-horizontal .suggest-feedback {
  right: 0px;
}

.contribution-container {
  padding-bottom: 5px;
}

.form-group--flex .multivalued-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: @screen-md) {
    margin-right: @grid-gutter-width / 2;
  }

  .input-group {
    display: flex;
  }
  .input-group-btn {
    width: auto;
  }

  .tag-input {
    margin-left: @grid-gutter-width / 2;
  }
}
// Placeholder for select
.select--empty,
option[value=''] {
  color: @input-color-placeholder;
}
.select--empty option:not([value='']) {
  color: @input-color;
}
.contributeurs-input-list {
  margin-top: 5px;
}


div[mediatheque-suggestions]>ul.dropdown-menu,
input[typeahead]+ul.dropdown-menu{
  max-height: 400px;
  overflow: auto;
}

.btn {
  .box-shadow(2px 2px 3px rgba(0,0,0,.4));

  &-default[disabled] {
    border: 1px solid @btn-default-border;
  }
}

.btn-link {
  .box-shadow(none);
  &.disabled,
  &[disabled] {
    color: red;
  }
}
form fieldset legend {
  padding-left: 15px;
  background-color: @legend-border-color;
}
.checkbox-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: @screen-lg-min) {
    margin-top: 2px;
  }
}
.checkbox-block-label {
  margin-top: 7px;
}
.fieldset-two-rows {
  position: relative;

  @media (min-width: @screen-lg-min) {
    // Fix pour FF: Firefox et Chrome ne réagissent pas pareil avec les fieldsets et les legends.
    // pour Firefox, top: 0 se situe en dessous de la légende
    // pour Chrome (et même IE), top: 0 correspond à la bordure haute du fieldset

    // Pour avoir le même comportement partout, on positionne de manière absolue l'élément legend
    // puis on applique un padding au fieldset pour laisser de la place à l'élément legend

    @legend-height: @font-size-base * 1.5 * @line-height-base;
    @legend-margin-bottom: @line-height-computed;

    padding-top: @legend-height + @legend-margin-bottom;

    legend {
      position: absolute;
      top: 0;
    }

    &::before {
      content: "";
      background-color: @legend-border-color;
      width: 2px;
      height: calc(~"100% - " (@legend-height + 10px));
      display: block;
      position: absolute;
      left: 50%;
      // FIXME: Firefox et Chrome ne réagissent pas pareil.
      // pour Firefox, top: 0 se situe en dessous de la légende
      // pour Chrome, top: 0 correspond à
      top: @legend-height + 5px;
      margin-left: -1 * @grid-gutter-width / 2 + 1;
    }
  }
}
.control-label {
  font-size: 14px;
  color: #333;

  &.control-label-mandatory::after {
    content: " *";
  }
}
.control-label.control-label-left {
  text-align: left;
}
input.form-control:focus {
  background-color: #eee;
  & ~ .suggest-feedback {
    background-image: radial-gradient(circle closest-side, #eee, rgba(238, 238, 238, 0));
  }
}

// Pour que le champ "Auteur de l'illustration" du formulaire d'intégration d'illustrations s'affiche correctement
// (Pour que la croix pour supprimer un auteur soit bien collée au champ)
// Méthode assez bancale mais assumée
.fix-croix {
  transform: translateX(-30px);
}

select,
select.form-control,
textarea,
textarea.form-control,
input.form-control,
input[type=text],
input[type=password],
input[type=email],
input[type=number],
[type=text].form-control,
[type=password].form-control,
[type=email].form-control,
[type=tel].form-control,
[contenteditable].form-control {
  &,
  &.disabled,
  &[disabled],
  &[readonly] {
    margin-bottom: 8px;
    padding-left: 3px;
    padding-right: 3px;
    .box-shadow(inset 0 0 0 1px #ddd);
  }

  &:focus {
    .box-shadow(inset 0 0 0 2px @brand-primary);
  }
}

.form-control.with-200 {
  width: 200px;
}

.btn-input-actions {
  padding: 5px;
  margin-bottom: 8px;
  margin-left: 10px;
}

.form-group .btn,
.form-control-static {
  margin-bottom: 8px;
}

input[type="checkbox"] ~ label {
  padding-left: 5px;
}

.form__phototheque legend {
  background-color: @categorie-phototheque-color;
  color: white;
}

.form__phototheque .form-control:focus {
  box-shadow: inset 0 0 0 2px @categorie-phototheque-color;
}

.form__biblio .form-control:focus {
  box-shadow: inset 0 0 0 2px @categorie-biblio-color;
}

.form__exemplaires-content {
  border: black solid 1px;
  padding: 2em 1em;
  margin-bottom: 1em;
}
