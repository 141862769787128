.modal-backdrop.am-fade {
  opacity: .5;
  transition: opacity .15s linear;
  &.ng-enter {
    opacity: 0;
    &.ng-enter-active {
      opacity: .5;
    }
  }
  &.ng-leave {
    opacity: .5;
    &.ng-leave-active {
      opacity: 0;
    }
  }
}
.modal-backdrop {
  bottom: 0px;
  height: 100vh;
  position: fixed;
}
// Fix du modal ayant un margin à droite supplémentaire à cause du bouton Close
.modal-header {
  .close {
    float: none;
  }
}
