.thesaurus-list {
  margin: 20px 0;
}

.thesaurus {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.thesaurus-buttons {
  float: right;
}

.thesaurus-child {
  margin-left: 4rem;
}
