// Mosaique d'images
.mosaic,
.gu-mirror {
  .mosaic-container {
    position: relative;

    &:hover .action.remove {
      &:extend(.text-danger);

      background-color: @mosaic-bg-hover;
      border-radius: 0;
      opacity: 1;
    }

    .action.remove {
      position: absolute;
      top: @mosaic-padding;
      right: @mosaic-remove-right;
      opacity: 0.5;
      color: @text-color;
    }
    .action.select {
      width: 25px;
    }
    .caption {
      width: @vignette;
      color: @mosaic-caption-color;

      .titre {
        font-weight: bold;
      }
      .sous-titre {
        display: block;
      }
    }
    .caption {
      height: @mosaic-caption-height;
    }
    &.biblio {
      height: @mosaic-biblio-height;
      .caption {
        width: @mosaic-biblio-width;
        padding-top: 25px;

        a {
          width: @mosaic-biblio-width - 50px;
        }
      }
    }

    a,
    a:hover,
    a:focus,
    a.active {
      color: @card-caption-color;
      text-decoration: none;
    }
  }
}
