.critere-operator {
  text-align: right;
  font-weight: bold;
  font-size: 14px;
}
@media (min-width: @screen-sm-min) {
  .critere-operator-wrapper {
    padding-top: (@padding-base-vertical + 1); // Comme control-label
  }
}
.expertsearch-inputs {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.expertsearch-filters {
  width: 64%;
}
.expertsearch-options {
  // 35% et non 36% pour laisser un peu d'espace entre les deux blocs
  width: 35%;

  .form-group:first-child {
    padding-bottom: 15px;
  }
}
.btn-add-critere {
  padding-bottom: 15px;
}
