//
// Collapse Button
// --------------------------------------------------

// The collapse button wrapper (div)
.collapse-button {
  position: relative;
  &.collapse-button-inline {
    display: inline-block;
  }
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.collapse-button:focus {
  outline: 0;
}

// The collapsible pabel (div)
.collapse-button-panel {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: @zindex-dropdown;
  display: none; // none by default, but block on "open" of the panel
  float: left;
  min-width: 250px;
  width: 100%;
  padding: 10px;
  margin: 2px 0 0; // override default ul
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: @dropdown-bg;
  border: 1px solid @dropdown-fallback-border; // IE8 fallback
  border: 1px solid @dropdown-border;
  border-radius: @border-radius-base;
  .box-shadow(0 6px 12px rgba(0,0,0,.175));
  background-clip: padding-box;

  // Dividers (basically an hr) within the panel
  .divider {
    .nav-divider(@dropdown-divider-bg);
//    height: 1px;
//    margin: ((@line-height-computed / 2) - 1) 0;
//    overflow: hidden;
//    background-color: @color;
  }
}

.collapse-button-width-350 .collapse-button-panel {
  width: 350px;
}

// Open state for the panel
// Show the panel
.in.collapse-button-panel {
  display: block;
}
