.breadcrumb.breadcrumb-thesaurus {
  padding: 0;
  margin-bottom: 0;
  background: none;
  border-radius: 0;
  opacity: 0.8
}

span.interdit {
  color: @breadcrumb-active-color;
  font-style: italic;
}
  
.dropdown-menu .active .breadcrumb-thesaurus,
.dropdown-menu .active .breadcrumb-thesaurus .active,
.dropdown-menu a:hover .breadcrumb-thesaurus,
.dropdown-menu a:hover .breadcrumb-thesaurus .active {
  color: inherit;
}
