.consultation-illustration-pictures {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.consultation-illustration-pictures-downloader {
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.consultation-illustration-pictures-vignette {
  display: flex;
  align-items: center;
  justify-content: center;
  height: @vignette;
  width: 75%;
}

.consultation-illustration-pictures-vignette img {
  height: 100%;
  width: 100%;
  object-fit: contain;

  &[src=''], &:not([src]) {
    display: none;
  }
}

.consultation-illustration-pictures-modifier .btn-xs {
  font-size: @font-size-base;
}
