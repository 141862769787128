.progress.nomargin {
  margin-bottom: 0px;
}

.progress-bar-gray {
  .progress-bar-variant(@progress-bar-gray-bg);
}

.progress {
  &-bar {
    &-gray:last-child.progress-bar:before {
      background-color: lighten(@progress-bar-gray-bg, 20%);
    }
  }
}
